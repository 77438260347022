<template>
  <v-container fluid>
    <v-card tile flat>
      <skeleton-page-heading v-if="loading" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <action-bar
          :show="false"
          :refresh="refresh"
          :loading="loading"
          :search="search"
          :search-data="searchData"
        />
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <skeleton-table v-if="loading" />
        <server-side-table
          v-else
          :headers="headers"
          :footers="footers"
          :total-items="totalItems"
          :items="items"
          :loading="loading"
          :update-item-per-page="updateItemPerPage"
          :get-data-from-api="getDataFromApi"
          :remove-item="removeItem"
          :from="from ? from : 0"
          :total="total"
          :page="page"
          :route-detail="routeDetail"
          :action-show="true"
          :action-delete="false"
          :action-edit="false"
          :action-detail="true"
          :action-status="false"
          :action-approval="false"
          :action-email-verified="true"
          :action-remove="true"
          :remove="removeVisitor"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/mixin";
import { remove } from "@/api/visitor";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import ActionBar from "../components/ActionBar.vue";
import ServerSideTable from "../components/ServerSideTable.vue";
import SkeletonTable from "../components/SkeletonTable.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import Swal from 'sweetalert2'

export default {
  components: {
    BreadcrumbComponent,
    ActionBar,
    ServerSideTable,
    SkeletonTable,
    SkeletonPageHeading,
  },
  mixins: [mixin],
  data: () => ({
    url: "visitors",
    routeDetail: 'visitor-detail',
    headers: [
      { text: "#", value: "order" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Status Verified", value: "email_verified_at" },
      { text: "Action", value: "actions" },
    ],
  }),
  methods: {
    async removeVisitor(id) {
      Swal.fire({
        title: 'Yakin hapus data ini ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("auth/setLoading");
         await remove(id)
          this.$store.dispatch("auth/setLoading");
          Swal.fire({
            title: 'Hapus Sukses',
            text: 'Data anda telah terhapus',
            icon: 'success',
            timer: 1000
          })
          this.getDataFromApi(this.page)
        }
      })
      
    }
  },
};
</script>

<style></style>
